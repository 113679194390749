
import React, { useEffect, useState } from "react"
import { graphql } from 'gatsby'
import Layout from '../components/layout';
import Seo from '../components/seo';
import Helmet from "react-helmet"
import { withPrefix } from 'gatsby';
import RichText from '../components/richText';
import Openings from '../components/openings'

import {
  Formik,
  Form,
  Field,
  ErrorMessage,
  useFormikContext
} from 'formik'

const Contact = ({ data }) => {

  useEffect(() => {
    document.querySelector('html').classList.add('smooth');
  }, []);

  //Form

  let errors = {};

  const encode = (data) => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&");
  }

  const [loading, setLoading] = React.useState(false);
  const [consent, setConsent] = React.useState(true);


  const handleMessage = () => {

    const $form = document.getElementById('contact-form');
    let popupInTimer = null;

    // Clear timers
    clearTimeout(popupInTimer);
    popupInTimer = null;

    $form.classList.add('is-sent');
    $form.setAttribute('data-success', true)

    // Hide webpage after popup's IN animation
    popupInTimer = setTimeout(function () {

      $form.classList.remove('is-sent');
      $form.removeAttribute('data-success')

    }, 6500);



  }



  //Patch data
  if (!data) return null

  const $metaData = data.prismicMetadata.data;
  const $cta = data.prismicCalltoaction ? data.prismicCalltoaction.data : null;
  const $footer = data.prismicFooter.data;
  const $clutch = data.prismicClutch ? data.prismicClutch.data : null;
  const $socials = data.prismicSocials ? data.prismicSocials.data : null;
  const $contactUs = data.prismicContactUs ? data.prismicContactUs.data : null;

  const $getInTouch = data.prismicGetInTouch.data;
  const $form = $getInTouch.body[0];

  let filtersList = data.allPrismicFilter ? data.allPrismicFilter.edges : null;


  const handleChange = (e, values) => {
    
      values['interested'].push(e.target.value)

  }
  
  const handleConsent = (e,values) => {
    consent ? setConsent(false) : setConsent(true)
    e.target.value ? values['consent'] = true : values['consent'] = false;
  }


  return (

    <Layout cta={$cta} footer={$footer} clutch={$clutch} socials={$socials} contactUs={$contactUs} headerTransparent={true}>

      <Seo metadata={$metaData} title={$getInTouch.page_title ? $getInTouch.page_title : null} description={$getInTouch.page_description ? $getInTouch.page_description : null} keywords={$getInTouch.page_keywords ? $getInTouch.page_keywords : null}></Seo>

      <Helmet>

        {/* No inline CSS */}
        <link href={withPrefix('../../assets/css/sitio/contact.viewport.css')} rel="stylesheet" type="text/css" />
        <link href={withPrefix('../../assets/css/sitio/contact.form.css')} rel="stylesheet" type="text/css" />

        <script src="../../assets/js/sitio/contact.js" />
        <script src="../../assets/js/sitio/openings.js" />

      </Helmet>

      <main>
        <section aria-label={$getInTouch.contact_kicker}>
          <div className="c-contact-hero c-hero">
            <div className="c-contact-hero__holder o-wrapper">
              <p className="c-heading__kicker">{$getInTouch.contact_kicker}</p>
              <div className="c-contact-hero__tagline">
                <h2>{$getInTouch.contact_title}</h2>
                {$getInTouch.contact_description && <RichText render={$getInTouch.contact_description.richText}></RichText>}
              </div> {/* .c-hero__tagline */}
            </div> {/* .c-hero__holder */}

            <Openings isContactPage={true} />

          </div> {/* .c-hero */}


          <div className="c-contact o-section--b o-wrapper">
            <div className="c-contact__title">
              {$form.primary.form_title && <RichText render={$form.primary.form_title.richText}></RichText>}
            </div> {/* .c-contact__title */}

            <div className="c-contact__row">



              <Formik

                initialValues={{
                  name: '',
                  email: '',
                  phone: '',
                  interested: [],
                  description: '',
                  subject: '',
                  utmmedium: '',
                  utmsource: '',
                  utmcampaign: '',
                  consent: '',
                }}
                onSubmit={
                  (values, actions) => {

                    setLoading(true);

                    localStorage.getItem('utm_medium') != null ? values.utmmedium = localStorage.getItem('utm_medium') : values.utmmedium = null;
                    localStorage.getItem('utm_source') != null ? values.utmsource = localStorage.getItem('utm_source') : values.utmsource = null;
                    localStorage.getItem('utm_campaign') != null ? values.utmcampaign = localStorage.getItem('utm_campaign') : values.utmcampaign = null;

                    fetch("/", {
                      method: "POST",
                      headers: { "Content-Type": "application/x-www-form-urlencoded" },
                      body: encode({ "form-name": "contact-form", ...values })
                    })
                      .then(() => {
                        setLoading(false);
                        handleMessage();

                        window.dataLayer.push({
                          event: 'act-getintouch-submission',
                        })

                        setConsent(false);
                        actions.resetForm();

                        //checkboxes
                        let $inputsArray = document.querySelectorAll('.c-form__input');
                        Array.prototype.forEach.call($inputsArray, function ($input, i) {
                          $input.classList.remove('has-value');
                        })
                        document.querySelectorAll('.c-checkbox__input').forEach(element => {
                            element.checked = false;
                        });


                      })
                      .catch(() => {

                        setLoading(false);
                        handleMessage();

                      })
                      .finally(() => {

                        actions.setSubmitting(false)

                      })

                  }
                }
                validate={values => {

                  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
                  errors = {};

                  if (!values.name) {
                    errors.name = 'This field is required'
                  }
                  if (!values.email || !emailRegex.test(values.email)) {
                    errors.email = 'Email must be valid'
                  } else {
                    values.subject = `Web lead from ${values.email}`
                  }

                  return errors;


                }}
              >
                {({ values, touched }) => (

                  <Form key="contact-form" id="contact-form" name="contact-form" method="POST" className="c-form c-contact__col c-contact__form" data-netlify="true" netlify-honeypot="bot-field">

                    <p className="u-hidden-for-seo">
                      <label><input name="bot-field" /></label>
                    </p>

                    <input type="hidden" name="form-name" value="contact-form" />

                    <Field name="utmmedium" type="hidden" />
                    <Field name="utmsource" type="hidden" />
                    <Field name="utmcampaign" type="hidden" />

                    {$form.items.map((field, i) => {

                      if (field.type !== "textarea" && field.type !== "select") {

                        return (

                          <>
                            <label key={i} className={`c-form__group ${errors[field.name] && touched[field.name] ? 'has-error' : ''}`}>
                              <Field name={field.name} type={field.type} className="c-form__input js-form__checkIfEmpty" />
                              <span className="c-form__label">{field.text} {(field.name == "name" || field.name == "email") && <small>*</small>}</span>
                            </label>
                            <span className="c-form__error"><ErrorMessage name={field.name} /></span>
                          </>

                        )
                      } else if (field.type === "textarea") {

                        return (
                          <label key={i} className="c-form__group">
                            <Field name={field.name} as="textarea" rows={4} className="c-form__input js-form__checkIfEmpty" />
                            <span className="c-form__label">{field.text}</span>
                          </label>
                        )

                      } else {

                        return (
                          filtersList.length > 0 &&
                          <div key={i} className="c-form__group">
                            <label className="c-form__label c-form__label--fixed">{field.text}</label>


                            <div role="group" aria-labelledby="checkbox-group" className="c-form__checkboxes">

                              {filtersList.slice(0).reverse().map((item, j) => {

                                return (
                                  <label key={j} className="c-form__checkbox c-checkbox u-tap-size">
                                    <input type="checkbox" value={item.node.uid} name="interested[]" className="c-checkbox__input" onChange={(e)=> handleChange(e, values)}/>
                                    <span className="c-checkbox__checkbox">{item.node.data.filter_name}</span>
                                  </label>
                                )
                              })}

                              <label className="c-form__checkbox c-checkbox u-tap-size">
                                <input name="interested[]" type="checkbox" value="staff" className="c-checkbox__input"  onChange={(e)=> handleChange(e, values)}/>
                                <span className="c-checkbox__checkbox">Staff augmentation</span>
                              </label>

                              <label className="c-form__checkbox c-checkbox u-tap-size">
                                <input name="interested[]" type="checkbox" value="other" className="c-checkbox__input"  onChange={(e)=> handleChange(e, values)}/>
                                <span className="c-checkbox__checkbox">Other</span>
                              </label>

                            </div>
                            

                          </div>
                        )
                      }


                    })}

                    <label className="c-form__consent">
                        <input type="checkbox" name="consent" className="c-form__consent-input" value={consent} onChange={(e)=> handleConsent(e,values)} />
                        <span className="c-form__consent-btn"></span>
                        <span className="c-form__consent--checkbox">I agree to receive other communications from CreateThrive.</span>
                    </label>

                    <div className="c-form__bottom c-form__hideOnSuccess">
                      {!loading && <button type="submit" className="c-form__submit c-btn c-btn--icon u-tap-size" data-loading-text="Loading..."><span>Submit</span><span className="c-btn__icon" /></button>}
                      {loading && <button type="submit" class="c-form__submit c-btn c-btn--icon u-tap-size is-loading" data-loading-text="Loading..." disabled><span>Loading...</span><span class="c-btn__icon"></span></button>}
                      <span className="c-form__caption">* Required fields</span>
                    </div> {/* .c-form__bottom */}

                    <div className="c-form__bottom c-form__showOnSuccess">
                      <p className="c-form__message--success c-form__submit c-btn">
                        <span className="c-form__message-icon" />
                        <span>Your contact form has been sent successfully! Our sales team will be in touch soon.</span>
                      </p>
                    </div>

                    <div className="c-form__bottom"></div>

                  </Form>

                )}

              </Formik>


              <div className="c-contact__col c-contact__col--xs c-sidebar">
                {/* Contact */}
                <div className="c-sidebar__group">
                  <h3 className="c-sidebar__title">{$contactUs.contact_title}</h3>
                  <ul className="c-sidebar__data c-sidebar-data">
                    {$contactUs.items.map((item, i) => (
                      <li className="c-sidebar-data__item">
                        {item.contact_link && item.contact_link.url && <a href={item.contact_link.url} target="_blank" rel="noopener noreferrer">{item.contact_text}</a>}
                        {!item.contact_link.url && <>{item.contact_text}</>}
                        <span className={`c-sidebar-data__icon icon-${item.contact_icon}-stroke`} />
                      </li>
                    ))}

                  </ul> {/* .c-sidebar-data */}
                </div> {/* .c-footer__col */}



                {/* Social */}
                {$socials && $socials.items.length > 0 &&
                  <div className="c-sidebar__group">
                    <h3 className="c-sidebar__title">{$socials.socials_title}</h3>
                    <ul className="c-sidebar__social c-social c-social--cta">

                      {$socials.items.map((item, i) => (
                        item.social_link && item.social_link.url &&
                        <li key={i} className="c-social__item">
                          <a href={item.social_link.url} target="_blank" rel="noopener noreferrer" className={`c-social__link u-tap-size icon-social-${item.social_icon}`} title={item.social_name}>
                            <span className="u-hidden-for-seo">{item.social_name}</span>
                          </a>
                        </li>
                      ))}

                    </ul> {/* .c-social */}
                  </div>
                }


              </div> {/* .c-contact__col */}
            </div> {/* .c-contact__row */}
          </div> {/* .c-contact */}
        </section>


        {/* <Openings/> */}


      </main>


    </Layout>


  )
}

export const query = graphql`
  query ContactQuery {
          prismicGetInTouch {
        data {
          body {
        ... on PrismicGetInTouchDataBodyForm {
          primary {
        form_title {
          richText
        }
        }
        items {
          name
          text
          type
        }
      }
    }
        contact_description {
          richText
        }
        contact_kicker
        contact_title
        page_title
        page_description
        page_keywords
      }
      uid
    }
    allPrismicFilter {
      edges {
        node {
          data {
            filter_name
          }
          uid
        }
      }
    },
        prismicMetadata {
          ...MetadataFragment
        },
    prismicClutch {
          ...ClutchFragment
        },
    prismicFooter{
          ...FooterFragment
        }
        prismicSocials{
          ...SocialsFragment
        }
        prismicContactUs{
          ...ContactUsFragment
        }
        prismicCalltoaction{
          ...CallToActionFragment
        }

        }
      `

export default Contact
