import React from "react"


const Openings = ({ isContactPage }) => {

      const [isOpened, setOpened] = React.useState( isContactPage ? false : true);

      return (
            <>
                  {/* OPENINGS */}
                  <aside className={`c-openings js-openings u-alt-font ${isOpened ? 'is-visible' : ''}`}>
                        <div className="c-openings__holder">
                              <div className="c-openings__bubble">
                                    <h2>Would you like to be <strong>part of our team?</strong></h2>
                                    <p>We are always hiring.</p>
                                    <a href="https://createthrive.bamboohr.com/jobs/" target="_blank" rel="noopener" className="c-openings__link c-link u-tap-size">View openings<span className="c-link__icon" /></a>
                                    <button type="button" onClick={() => {setOpened(false)}} className="c-openings__cross js-openings__hide u-tap-size" title="Hide modal"><span /></button>
                              </div> {/* .c-openings__bubble */}
                              <button type="button"   onClick={() => {setOpened(true)}} className="c-openings__btn js-openings__btn u-tap-size">
                                    <span className="c-openings__circle" />
                                    <span className="c-openings__text">We are hiring!</span>
                              </button> {/* .c-openings__btn */}
                        </div> {/* .c-openings__holder */}
                  </aside> {/* .c-openings */}

            </>
      )

}


export default Openings